import React, { Component } from 'react'
import './App.css'

class App extends Component {
  constructor () {
    super()

    this.state = {
      x: 200,
      y: 200,
      angle: 0
    }

    this.canvas = React.createRef()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (event) {
    const canvas = this.canvas.current
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, 400, 400)
    const x = event.clientX - canvas.getBoundingClientRect().left // x == the location of the click in the document - the location (relative to the left) of the canvas in the document
    const y = event.clientY - canvas.getBoundingClientRect().top // y == the location of the click in the document - the location (relative to the top) of the canvas in the document

    ctx.beginPath()
    ctx.moveTo(200, 200)
    ctx.lineTo(x, y)
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.arc(x, y, 20, 0, Math.PI * 2, true)
    ctx.fillStyle = '#ff2626'
    ctx.fill()
    ctx.closePath()

    const p1 = {
      x: 200,
      y: 200
    }

    // angle in degrees
    let angle = ((Math.atan2(y - p1.y, x - p1.x) * 180 / Math.PI) + 90)
    if (angle > 180) angle = -360 + angle

    this.setState({ x, y, angle: angle.toFixed(2) })
  }

  render () {
    return (
      <div className='wrap'>
        <div className='reference-arc' />
        <div className='middle'>
          <br />
          <br />
          <span>
            Current point: {this.state.x};{this.state.y}
          </span>
          <br />
          <span>
            Angle: {this.state.angle}
          </span>
        </div>
        <canvas
          ref={this.canvas}
          width='400' height='400'
          onClick={this.handleClick}
        />
      </div>
    )
  }
}

export default App
